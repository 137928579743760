import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { countMatchs } from 'app/services/autoMatching';
// import a service or use axios directly

/**
 * Thunk to fetch the number of matches for the logged-in user
 */
export const fetchMatchCount = createAsyncThunk(
    'match/fetchMatchCount',
    async (_, thunkAPI) => {
        try {
            // Make an API request (e.g., to /api/matches/count)
            const response = await countMatchs();
            // The service might return { data: { count: 5 } }, or similar
            return response.data;
        } catch (error) {
            // Return a descriptive error (and optionally dispatch a message, etc.)
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const matchSlice = createSlice({
    name: 'match',
    initialState: {
        propertyCount: 0,
        clientCount: 0,
        total: 0,
        loading: false,
        error: null,
    },
    reducers: {
        // If you need manual changes (for example, if user denies a property match)
        decrementPropertyCount: (state) => {
            state.propertyCount = Math.max(0, state.propertyCount - 1);
            // Optionally update total as well:
            state.total = Math.max(0, state.total - 1);
        },
        decrementClientCount: (state) => {
            state.clientCount = Math.max(0, state.clientCount - 1);
            // Optionally update total as well:
            state.total = Math.max(0, state.total - 1);
        },
        // If you ever want to update total alone, you can add more reducers
    },
    extraReducers: (builder) => {
        builder
            // fetchMatchCount
            .addCase(fetchMatchCount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMatchCount.fulfilled, (state, action) => {
                state.loading = false;
                // Suppose action.payload = { property_count, client_count, total_count }
                state.propertyCount = action.payload.total_properties;
                state.clientCount = action.payload.total_clients;
                state.total = action.payload.total_count;
            })
            .addCase(fetchMatchCount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const {
    decrementPropertyCount,
    decrementClientCount,
    // etc.
} = matchSlice.actions;

export default matchSlice.reducer;

