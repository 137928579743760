export const languages = [
	{ label: 'Français', value: 1 },
	{ label: 'English', value: 2 },
	{ label: 'Deutsch', value: 3 }
];

export const matchingRequestTypes = [
	{ label: "DEMANDE D'INFO", value: 'FROM_SEARCH' },
	{ label: "ISSU D’UN RAPPROCHEMENT", value: 'FROM_CLIENT_PROJECT' },
	{ label: "ISSU D’UN RAPPROCHEMENT", value: 'FROM_PROPERTY' },
];

export const PROPERTY_TYPE_CONSTANTS = {
	APPARTEMENT: 1,
	HOUSE: 2,
	TERRAIN: 3,
	// CHALET: 5,
	LOCAUX_MUR_PRO: 4,
	FOND_DE_COMMERCE: 7,
	DROIT_AU_BAIL: 8,
	IMMEUBLE: 6,
};

export const propertyTypes = [
	{ label: 'Appartement', value: PROPERTY_TYPE_CONSTANTS.APPARTEMENT },
	{ label: 'Maison', value: PROPERTY_TYPE_CONSTANTS.HOUSE },
	{ label: 'Terrain', value: PROPERTY_TYPE_CONSTANTS.TERRAIN },
	{ label: 'Locaux / Mur pro', value: PROPERTY_TYPE_CONSTANTS.LOCAUX_MUR_PRO },
	{ label: 'Fond de commerce', value: PROPERTY_TYPE_CONSTANTS.FOND_DE_COMMERCE },
	{ label: 'Droit au bail', value: PROPERTY_TYPE_CONSTANTS.DROIT_AU_BAIL },
	// { label: 'Chalet', value: PROPERTY_TYPE_CONSTANTS.CHALET },
	{ label: 'Immeuble', value: PROPERTY_TYPE_CONSTANTS.IMMEUBLE },
];

export const country = [
	{ label: 'France', value: "FRA" },
	{ label: 'Suisse', value: "CHE" },
];

export const MANAGEMENT_BROADCASTING_SOFTWARE = [
	{ label: 'Immofacile', value: "Immofacile" },
	{ label: 'Hektor', value: "Hektor" },
	{ label: 'APImmo', value: "APImmo" },
	{ label: 'Netty', value: "Netty" },
	{ label: 'Bunji', value: "Bunji" },
	{ label: 'Adapt Immo', value: "Adapt Immo" },
	{ label: 'Whise', value: "Whise" },
	{ label: 'Mon Logiciel Immobilier', value: "Mon Logiciel Immobilier" },
	{ label: 'Ymo App', value: "Ymo App" },
	{ label: 'Transactimo', value: "Transactimo" },
	{ label: 'WinImmobilier', value: "WinImmobilier" },
	{ label: 'Lesty', value: "Lesty" },
	{ label: 'Vilogi', value: "Vilogi" },
	{ label: 'BeYat', value: "BeYat" },
	{ label: 'Idealsoft', value: "Idealsoft" },
	{ label: 'La Loupe Immo', value: "La Loupe Immo" },
	{ label: 'Immostore', value: "Immostore" },
	{ label: 'JviewImmo', value: "JviewImmo" },
	{ label: 'Oqoro', value: "Oqoro" },
	{ label: 'Krier', value: "Krier" },
	{ label: 'Drive Fox', value: "Drive Fox" },
	{ label: 'Zelok', value: "Zelok" },
	{ label: 'Eudonet CRM', value: "Eudonet CRM" },
	{ label: 'Orvea Proptech Solutions', value: "Orvea Proptech Solutions" },
	{ label: 'Valhala', value: "Valhala" },
	{ label: 'Crypto', value: "Crypto" },
	{ label: 'SweepBright', value: "SweepBright" },
	{ label: 'Veliko', value: "Veliko" },
	{ label: 'Gercop', value: "Gercop" },
	{ label: 'LOCKimmo', value: "LOCKimmo" },
	{ label: 'Studio Net', value: "Studio Net" },
	{ label: 'Autres', value: "Autres" },
];


export const accountTypes = [
	{ label: 'Agent / Agence Immobilière', value: 1 },
	{ label: 'Mandataire / Agent Commercial', value: 2 },
	{ label: 'Gestionnaire de fortune / Family Office / Marchand de biens', value: 3 },
];

export const numberOfRooms = [
	{ label: '1 pièce', value: 1 },
	{ label: '2 pièces', value: 2 },
	{ label: '3 pièces', value: 3 },
	{ label: '4 pièces', value: 4 },
	{ label: '5 pièces', value: 5 },
	{ label: '6 pièces', value: 6 },
	{ label: '7 pièces', value: 7 },
	{ label: '8 pièces', value: 8 },
	{ label: '9 pièces', value: 9 },
	{ label: '10 pièces et +', value: 10 },
];

export const tolerance = [
	{ label: '0%', value: 0 },
	{ label: '-5%', value: 5 },
	{ label: '-10%', value: 10 },
	{ label: '-15%', value: 15 },
];

export const tolerancePrice = [
	{ label: '0%', value: 0 },
	{ label: '+5%', value: 5 },
	{ label: '+10%', value: 10 },
	{ label: '+15%', value: 15 },
];

export const propertyAnnexes = [
	{ label: 'Garage', value: 1 },
	{ label: 'Place Privative / Parking', value: 2 },
	{ label: 'Piscine', value: 3 },
];

export const propertyAnnexesPro = [
	{ label: 'Garage', value: 1 },
	{ label: 'Place Privative / Parking', value: 2 },
];

export const honoraryTypes = [
	{ label: 'Vendeur', value: 1 },
	{ label: 'Acquéreur', value: 2 },
];

export const staffTypes = [
	{ label: 'Salariée', value: "EMPLOYEE" },
	{ label: 'Agent Commercial', value: "COMMERCIAL_AGENT" },
];

export const propertyStates = [
	{ label: 'En vente', value: 1 },
	{ label: 'Vendu par THM', value: 2 },
	{ label: 'Vendu par autre plateforme', value: 3 },
	{ label: 'Non vendu', value: 4 },
	{ label: 'Archivé', value: 5 }
];

export const propertyPublishListing = [
	{ label: 'En ligne ✅', value: 1 },
	{ label: 'Hors ligne ⛔️', value: 0 },
];

export const hexColorsEnergyEfficiencyScale = {
	'A': '#4CAF50', // green-500
	'B': '#81C784', // green-300
	'C': '#FFF176', // yellow-300
	'D': '#FFEB3B', // yellow-500
	'E': '#FFB74D', // orange-300
	'F': '#FB8C00', // orange-500
	'G': '#F44336'  // red-500
};

export const nonLoggedInRoutes = ['/login', '/create/step1', '/create/step2', '/verify', '/reset-password', '/reset-password/:token'];


export const registrationSource = [
	{ label: 'Via une recommendation', value: "recommendation" },
	{ label: 'Via un email reçu', value: "email" },
	{ label: 'Via Linkedin', value: "linkedin" },
	{ label: 'Via la FNAIM', value: "fnaim" },
	{ label: 'Via SMS', value: "sms" },
	{ label: 'Via une recherche Google', value: "google" },
	{ label: 'Via Instagram', value: "instagram" },
	{ label: 'Via Facebook', value: "facebook" },
];


export const departmentsAndRegions = [
	{ departmentNumber: "01", departmentName: "Ain", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "02", departmentName: "Aisne", region: "Hauts-de-France" },
	{ departmentNumber: "03", departmentName: "Allier", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "04", departmentName: "Alpes-de-Haute-Provence", region: "Provence-Alpes-Côte d'Azur" },
	{ departmentNumber: "05", departmentName: "Hautes-Alpes", region: "Provence-Alpes-Côte d'Azur" },
	{ departmentNumber: "06", departmentName: "Alpes-Maritimes", region: "Provence-Alpes-Côte d'Azur" },
	{ departmentNumber: "07", departmentName: "Ardèche", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "08", departmentName: "Ardennes", region: "Grand Est" },
	{ departmentNumber: "09", departmentName: "Ariège", region: "Occitanie" },
	{ departmentNumber: "10", departmentName: "Aube", region: "Grand Est" },
	{ departmentNumber: "11", departmentName: "Aude", region: "Occitanie" },
	{ departmentNumber: "12", departmentName: "Aveyron", region: "Occitanie" },
	{ departmentNumber: "13", departmentName: "Bouches-du-Rhône", region: "Provence-Alpes-Côte d'Azur" },
	{ departmentNumber: "14", departmentName: "Calvados", region: "Normandie" },
	{ departmentNumber: "15", departmentName: "Cantal", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "16", departmentName: "Charente", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "17", departmentName: "Charente-Maritime", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "18", departmentName: "Cher", region: "Centre-Val de Loire" },
	{ departmentNumber: "19", departmentName: "Corrèze", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "2A", departmentName: "Corse-du-Sud", region: "Corse" },
	{ departmentNumber: "2B", departmentName: "Haute-Corse", region: "Corse" },
	{ departmentNumber: "21", departmentName: "Côte-d'Or", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "22", departmentName: "Côtes-d'Armor", region: "Bretagne" },
	{ departmentNumber: "23", departmentName: "Creuse", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "24", departmentName: "Dordogne", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "25", departmentName: "Doubs", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "26", departmentName: "Drôme", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "27", departmentName: "Eure", region: "Normandie" },
	{ departmentNumber: "28", departmentName: "Eure-et-Loir", region: "Centre-Val de Loire" },
	{ departmentNumber: "29", departmentName: "Finistère", region: "Bretagne" },
	{ departmentNumber: "30", departmentName: "Gard", region: "Occitanie" },
	{ departmentNumber: "31", departmentName: "Haute-Garonne", region: "Occitanie" },
	{ departmentNumber: "32", departmentName: "Gers", region: "Occitanie" },
	{ departmentNumber: "33", departmentName: "Gironde", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "34", departmentName: "Hérault", region: "Occitanie" },
	{ departmentNumber: "35", departmentName: "Ille-et-Vilaine", region: "Bretagne" },
	{ departmentNumber: "36", departmentName: "Indre", region: "Centre-Val de Loire" },
	{ departmentNumber: "37", departmentName: "Indre-et-Loire", region: "Centre-Val de Loire" },
	{ departmentNumber: "38", departmentName: "Isère", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "39", departmentName: "Jura", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "40", departmentName: "Landes", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "41", departmentName: "Loir-et-Cher", region: "Centre-Val de Loire" },
	{ departmentNumber: "42", departmentName: "Loire", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "43", departmentName: "Haute-Loire", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "44", departmentName: "Loire-Atlantique", region: "Pays de la Loire" },
	{ departmentNumber: "45", departmentName: "Loiret", region: "Centre-Val de Loire" },
	{ departmentNumber: "46", departmentName: "Lot", region: "Occitanie" },
	{ departmentNumber: "47", departmentName: "Lot-et-Garonne", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "48", departmentName: "Lozère", region: "Occitanie" },
	{ departmentNumber: "49", departmentName: "Maine-et-Loire", region: "Pays de la Loire" },
	{ departmentNumber: "50", departmentName: "Manche", region: "Normandie" },
	{ departmentNumber: "51", departmentName: "Marne", region: "Grand Est" },
	{ departmentNumber: "52", departmentName: "Haute-Marne", region: "Grand Est" },
	{ departmentNumber: "53", departmentName: "Mayenne", region: "Pays de la Loire" },
	{ departmentNumber: "54", departmentName: "Meurthe-et-Moselle", region: "Grand Est" },
	{ departmentNumber: "55", departmentName: "Meuse", region: "Grand Est" },
	{ departmentNumber: "56", departmentName: "Morbihan", region: "Bretagne" },
	{ departmentNumber: "57", departmentName: "Moselle", region: "Grand Est" },
	{ departmentNumber: "58", departmentName: "Nièvre", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "59", departmentName: "Nord", region: "Hauts-de-France" },
	{ departmentNumber: "60", departmentName: "Oise", region: "Hauts-de-France" },
	{ departmentNumber: "61", departmentName: "Orne", region: "Normandie" },
	{ departmentNumber: "62", departmentName: "Pas-de-Calais", region: "Hauts-de-France" },
	{ departmentNumber: "63", departmentName: "Puy-de-Dôme", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "64", departmentName: "Pyrénées-Atlantiques", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "65", departmentName: "Hautes-Pyrénées", region: "Occitanie" },
	{ departmentNumber: "66", departmentName: "Pyrénées-Orientales", region: "Occitanie" },
	{ departmentNumber: "67", departmentName: "Bas-Rhin", region: "Grand Est" },
	{ departmentNumber: "68", departmentName: "Haut-Rhin", region: "Grand Est" },
	{ departmentNumber: "69", departmentName: "Rhône", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "70", departmentName: "Haute-Saône", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "71", departmentName: "Saône-et-Loire", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "72", departmentName: "Sarthe", region: "Pays de la Loire" },
	{ departmentNumber: "73", departmentName: "Savoie", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "74", departmentName: "Haute-Savoie", region: "Auvergne-Rhône-Alpes" },
	{ departmentNumber: "75", departmentName: "Paris", region: "Île-de-France" },
	{ departmentNumber: "76", departmentName: "Seine-Maritime", region: "Normandie" },
	{ departmentNumber: "77", departmentName: "Seine-et-Marne", region: "Île-de-France" },
	{ departmentNumber: "78", departmentName: "Yvelines", region: "Île-de-France" },
	{ departmentNumber: "79", departmentName: "Deux-Sèvres", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "80", departmentName: "Somme", region: "Hauts-de-France" },
	{ departmentNumber: "81", departmentName: "Tarn", region: "Occitanie" },
	{ departmentNumber: "82", departmentName: "Tarn-et-Garonne", region: "Occitanie" },
	{ departmentNumber: "83", departmentName: "Var", region: "Provence-Alpes-Côte d'Azur" },
	{ departmentNumber: "84", departmentName: "Vaucluse", region: "Provence-Alpes-Côte d'Azur" },
	{ departmentNumber: "85", departmentName: "Vendée", region: "Pays de la Loire" },
	{ departmentNumber: "86", departmentName: "Vienne", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "87", departmentName: "Haute-Vienne", region: "Nouvelle-Aquitaine" },
	{ departmentNumber: "88", departmentName: "Vosges", region: "Grand Est" },
	{ departmentNumber: "89", departmentName: "Yonne", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "90", departmentName: "Territoire de Belfort", region: "Bourgogne-Franche-Comté" },
	{ departmentNumber: "91", departmentName: "Essonne", region: "Île-de-France" },
	{ departmentNumber: "92", departmentName: "Hauts-de-Seine", region: "Île-de-France" },
	{ departmentNumber: "93", departmentName: "Seine-Saint-Denis", region: "Île-de-France" },
	{ departmentNumber: "94", departmentName: "Val-de-Marne", region: "Île-de-France" },
	{ departmentNumber: "95", departmentName: "Val-d'Oise", region: "Île-de-France" }
];

export const globalImageSize = `
  w-full 
  object-cover 
  h-[12rem]      /* default for smallest screens (>=0px) */
  sm:h-[10rem]   /* for screens >= 640px */
  md:h-[12rem]   /* for screens >= 768px */
  xl:h-[12rem]   /* for screens >= 1280px */
  2xl:h-[18rem]   /* for screens >= 1280px */
  3xl:h-[22rem]   /* for screens >= 1280px */
`.trim();

export const globalImageSizeModal = `
  w-full 
  object-cover
  h-48        /* default for small screens (min-width: 0) */
  sm:h-64     /* for screens >= 640px */
  md:h-64     //* for screens >= 768px */
  xl:h-[22rem]  /* for screens >= 1280px */                                    
`.trim();
