import axios from 'axiosInstance.js';

export const fromClients = async () => {
	return axios.get(`matching/clients`);
};

export const fromProperties = async () => {
	return axios.get(`matching/properties`);
};

export const hideMatch = async (matchId) => {
	return axios.post(`matching/${matchId}/hide`);
};

export const countMatchs = async () => {
	return axios.get(`matching/count`);
};
